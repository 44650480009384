import { FC, FormEvent, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { Button } from 'primereact/button'
import { RadioButton } from 'primereact/radiobutton'
import { Checkbox } from 'primereact/checkbox'
import { Spinner } from '../../components/Spinner'
import {
  Customer,
  OrderItem,
  OrderPaymentType,
  Payment,
  ProductListResponse,
  useCreateOrderMutation,
  useGetCustomerMutation,
  useOrderPaymentTypesQuery,
  useGetExchangeReceiptInfoQuery,
  GetproductsForReceiptDocument,
} from '../../generated/graphql'
import { currencyFormat } from '../../helpers/currencyFormat'
import { Input } from '../../components/form'
import { BarcodeModule } from '../../components'
import { IoIosCash } from 'react-icons/io'
import { AiFillCreditCard, AiOutlineNumber } from 'react-icons/ai'
import { FaMobileAlt } from 'react-icons/fa'
import { AppState } from '../../redux/rootReducer'
import { useSelector } from 'react-redux'

const ReturnReceipt: FC<RouteComponentProps<{ id: string }>> = ({
  history,
  match,
}) => {
  const [items, setItems] = useState<ProductListResponse[]>([])
  const [paymentMethod, setPaymentMethod] = useState(1)
  const [payments, setPayments] = useState<Payment[]>([])
  const [cashAmount, setCashAmount] = useState('')
  const [cardAmount, setCardAmount] = useState('')
  const [balance, setBalance] = useState<number>(0)
  const [settleInPoints, setSettleInPoints] = useState<boolean>(false)
  const [customer, setCustomer] = useState<Customer>()
  const [date, setDate] = useState<Date>(new Date())
  const [receiptTotal, setReceiptTotal] = useState<number>(0)
  const [available, setAvailable] = useState<boolean>(false)
  const [isHcmloyality, setIsHcmLoyality] = useState(false);
  const [hcmloyality, setHcmLoyality] = useState('');
  const [hcmCustomerPhone, setHcmCustomerPhone] = useState('');
  const {
    loading: loadingOrderPayments,
    data: orderPayments,
  } = useOrderPaymentTypesQuery()
  const [form, setForm] = useState({
    amountPaid: '',
    removePoints: 0,
  })
  const { data: exchangeInfo } = useGetExchangeReceiptInfoQuery({
    variables: { input: Number(match.params.id) },
  })
  const [getCustomer, { loading: loadingCustomer }] = useGetCustomerMutation()
  const [createOrder, { loading: lodingCreateOrder }] = useCreateOrderMutation()
  const branchId = useSelector<AppState, number>(
    (state) => state.auth.user?.branch.id!,
  )

  const handleCustomer = async (e: FormEvent<HTMLInputElement>) => {
    const { data } = await getCustomer({
      variables: { phone: e.currentTarget.value },
    })
    if (!loadingCustomer && data) {
      const customer = data.getCustomer as Customer
      setCustomer(customer)
    }
  }

  const changePaymentMethod = (o: OrderPaymentType) => {
    setCardAmount('')
    setCashAmount('')
    setForm({ ...form, amountPaid: '' })
    setPaymentMethod(o.id)
    switch (o.id) {
      case 1:
        setPayments([{
          paymentType: 1,
          amount: receiptTotal - exchangeInfo?.getExchangeReceiptInfo?.storeCredit!
        }])
        break
      case 2:
        setForm({ ...form, amountPaid: String(receiptTotal) })
        setPayments([{
          paymentType: 2,
          amount: receiptTotal - exchangeInfo?.getExchangeReceiptInfo?.storeCredit!
        }])
        break
      case 3:
        setPayments([{ paymentType: 1, amount: 0 }, { paymentType: 2, amount: 0 }]);
        break
      default:
        break
    }
  }

  const handleSubmit = async (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    let updatedPayment: Payment[] = []; 
    const updateItems: Array<OrderItem> = items.map((i) => ({
      id: i.id,
      quantity: i.quantity,
      price: i.price,
      discount: i.discount,
      discountType: i.discount_type,
      addToStock: 0,
    }))
    if (paymentMethod === 3) {
      const p = [...payments]
      const x = p.find((p) => p.paymentType === 1)
      const y = p.find((p) => p.paymentType === 2)
      x!.amount = Number(cashAmount)
      y!.amount = Number(cardAmount)
      updatedPayment = [x!, y!];
      setPayments([x!, y!]);
    } else if (paymentMethod === 2) {
      updatedPayment = [{
        paymentType: 2, amount: receiptTotal - exchangeInfo?.getExchangeReceiptInfo?.storeCredit!
      }];
      setPayments([{ paymentType: 2, amount: receiptTotal - exchangeInfo?.getExchangeReceiptInfo?.storeCredit! }])
    } else {
      updatedPayment = payments;
    }
    try {
      const { data } = await createOrder({
        variables: {
          input: {
            hcmLoyality: Number(hcmloyality),
            amountPaid:
              paymentMethod === 2
                ? receiptTotal -
                  exchangeInfo?.getExchangeReceiptInfo?.storeCredit!
                : Number(form.amountPaid),
            branchId,
            addPoints: (Number(form.amountPaid) * 1) / 100,
            settleInPoints,
            customerId: customer?.id,
            removePoints: form.removePoints,
            items: updateItems,
            payments: updatedPayment,
            isReturn: true,
            returnId: exchangeInfo?.getExchangeReceiptInfo?.returnId,
            date: date.toISOString(),
            hcmCustomerPhone
          },
        },
        refetchQueries: [{ query: GetproductsForReceiptDocument, variables: { input: 'receipt' } }]
      })
      history.replace(
        `/sales/return/receipt/preview/${data?.createOrder.orderNumber}`,
      )
    } catch (error) {
      console.log(error)
    }
  }

  return lodingCreateOrder ? (
    <Spinner />
  ) : (
    <form>
      <>
        <BarcodeModule
          getDate={setDate}
          dateName="Receipt"
          headers={{ priceType: 'Unit', discount: true }}
          showInvoiceNumber={true}
          getItems={setItems}
          receiptTotal={setReceiptTotal}
          checkAvailability
          getAvailable={setAvailable}
          balance={balance}
            type="receipt"
            hcmLoyality={hcmloyality}
          />
          {branchId === 3 && ((receiptTotal -
                  exchangeInfo?.getExchangeReceiptInfo?.storeCredit! > 0) || hcmloyality !== '') && <div className="p-grid" style={{ marginRight: '2rem', marginBottom: '2rem' }}>
              <div className="p-col-4 p-offset-8" style={{ textAlign: 'right' }}>
                <RadioButton
                  name="hcmLoyality"
                  checked={isHcmloyality}
                  value={isHcmloyality}
                  onChange={() => setIsHcmLoyality(state => !state)}
                />
                <label htmlFor="cash" style={{ fontSize: '.9rem', marginLeft: '1rem' }}>Add loyality points</label>
                {isHcmloyality && <Input
                  name="hcmLoyality"
                  icon={<AiOutlineNumber />}
                  value={hcmloyality}
                  change={(e) => setHcmLoyality(e.currentTarget.value)}
                  keyUp={(e) => setHcmLoyality(e.currentTarget.value)}
                  placeholder="Loyality points"
                />}
              </div>
            </div>}
            {branchId === 3 && <div className="p-grid">
              <div className="p-col-3 p-offset-9" style={{ textAlign: 'right' }}>
                <Input
                  name="hcmCustomerPhone"
                  icon={<FaMobileAlt />}
                  change={(e) => setHcmCustomerPhone(e.currentTarget.value)}
                  keyUp={(e) => setHcmCustomerPhone(e.currentTarget.value)}
                  placeholder="Phone Number"
                  label="HCM customer phone number"
                />
              </div>
            </div>}
        <div
          className="p-grid"
          style={{
            marginBottom: '2rem',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          <div className="p-col">&nbsp;</div>
          <div className="p-col">&nbsp;</div>
          <div className="p-col">&nbsp;</div>
          <div className="p-col">&nbsp;</div>
          <div className="p-col">
            <span style={{ fontWeight: 'normal' }}>Amount Payable</span>
          </div>
          <div className="p-col grid-table-header grid-table-value">
            <span style={{ marginLeft: '2rem', fontWeight: 'normal' }}>
              {currencyFormat(
                receiptTotal -
                  exchangeInfo?.getExchangeReceiptInfo?.storeCredit!,
              )}
            </span>
          </div>
        </div>
        {receiptTotal - exchangeInfo?.getExchangeReceiptInfo?.storeCredit! >
          0 &&
          items.length !== 0 && (
            <div className="p-grid">
              <div className="p-col-4">
                <Input
                  icon={<FaMobileAlt />}
                  name="phone"
                  change={(e) => handleCustomer(e)}
                  keyUp={(e) => handleCustomer(e)}
                  placeholder="Phone Number"
                  label="Add points to customer"
                />
                {loadingCustomer ? null : (
                  <div style={{ marginTop: '2.5rem' }}>
                    {customer && (
                      <>
                        <div
                          style={{ fontSize: '1.3rem', fontWeight: 'normal' }}
                        >
                          {customer?.title}. {customer?.first_name}{' '}
                          {customer?.last_name}
                        </div>
                        <div
                          className="p-d-fex p-ai-center"
                          style={{ fontSize: '1rem', marginTop: '1.5rem' }}
                        >
                          Available Points:{' '}
                          <span
                            style={{ fontSize: '1rem', fontWeight: 'bold' }}
                          >
                            {customer.points}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className="p-col-4 p-offset-4">
                <h4
                  style={{
                    marginTop: '1rem',
                    fontWeight: 'normal',
                    textAlign: 'right',
                    padding: '8px',
                  }}
                >
                  Payment Method
                </h4>
                <div className="p-d-flex p-justify-end">
                  {loadingOrderPayments
                    ? 'Loading order payments'
                    : orderPayments?.orderPaymentTypes.map((o, i) => (
                        <div
                          className="p-field-radiobutton"
                          key={o.id}
                          style={{
                            padding: '0 8px',
                            marginRight:
                              i !== orderPayments?.orderPaymentTypes.length - 1
                                ? '.6rem'
                                : 0,
                          }}
                        >
                          <RadioButton
                            name="paymentMethod"
                            checked={o.id === paymentMethod}
                            value={paymentMethod}
                            inputId="cash"
                            onChange={() => changePaymentMethod(o)}
                          />
                          <label htmlFor="cash" style={{ fontSize: '.9rem' }}>
                            {o.name}
                          </label>
                        </div>
                      ))}
                </div>
                {paymentMethod === 1 && (
                  <div className="p-grid">
                    <div className="p-col">
                      <Input
                        icon={<IoIosCash />}
                        name="cashAmount"
                        placeholder="Cash"
                        change={(e) => {
                          setCashAmount(e.currentTarget.value)
                          setForm({
                            ...form,
                            amountPaid: e.currentTarget.value,
                          })
                          setBalance(
                            receiptTotal -
                              exchangeInfo?.getExchangeReceiptInfo
                                ?.storeCredit! -
                              Number(e.currentTarget.value) <
                              0
                              ? Math.abs(
                                  receiptTotal -
                                    exchangeInfo?.getExchangeReceiptInfo
                                      ?.storeCredit! -
                                    Number(e.currentTarget.value),
                                )
                              : 0,
                          )
                          setPayments([
                            {
                              amount: receiptTotal -
                              exchangeInfo?.getExchangeReceiptInfo
                                ?.storeCredit!,
                              paymentType: 1,
                            },
                          ])
                        }}
                      />
                    </div>
                  </div>
                )}
                {paymentMethod === 3 && (
                  <div className="p-grid" style={{ marginTop: '1rem' }}>
                    <div className="p-col">
                      <Input
                        icon={<IoIosCash />}
                        name="cashAmount"
                        placeholder="Cash"
                        value={cashAmount}
                        change={(e) => {
                          setCashAmount(e.currentTarget.value)
                          setCardAmount(
                            String(
                              receiptTotal -
                                exchangeInfo?.getExchangeReceiptInfo
                                  ?.storeCredit! -
                                Number(e.currentTarget.value),
                            ),
                          )
                          setForm({
                            ...form,
                            amountPaid: String(
                              Number(cashAmount) + Number(cardAmount),
                            ),
                          })
                        }}
                      />
                    </div>
                    <div className="p-col">
                      <Input
                        icon={<AiFillCreditCard />}
                        placeholder="Card"
                        name="cardAmount"
                        value={cardAmount}
                        change={(e) => {
                          setCardAmount(e.currentTarget.value)
                          setCashAmount(
                            String(
                              receiptTotal -
                                exchangeInfo?.getExchangeReceiptInfo
                                  ?.storeCredit! -
                                Number(e.currentTarget.value),
                            ),
                          )
                          setForm({
                            ...form,
                            amountPaid: String(
                              Number(cashAmount) + Number(cardAmount),
                            ),
                          })
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        {receiptTotal - exchangeInfo?.getExchangeReceiptInfo?.storeCredit! >
          0 &&
          items.length !== 0 &&
          customer && (
            <div className="p-d-flex p-ai-center p-justify-end">
              <Checkbox
                onChange={(e) => setSettleInPoints(e.checked)}
                checked={settleInPoints}
              ></Checkbox>
              <h4
                style={{
                  marginLeft: '.6rem',
                  fontWeight: 'normal',
                  padding: '0 8px',
                }}
              >
                Settle using points
              </h4>
            </div>
          )}
        {settleInPoints &&
          receiptTotal - exchangeInfo?.getExchangeReceiptInfo?.storeCredit! >
            0 &&
          items.length !== 0 && (
            <Input
              columnSize="p-col-4 p-offset-8"
              name="removePoints"
              icon={<AiOutlineNumber />}
              change={(e) =>
                setForm({
                  ...form,
                  removePoints: parseFloat(e.currentTarget.value),
                })
              }
              placeholder="Points"
            />
          )}
        <div className="p-grid" style={{ marginTop: '2rem' }}>
          <div className="p-col-2 p-offset-10" style={{ textAlign: 'right' }}>
            <Button
              className="button"
                type="button"
                onClick={handleSubmit}
              disabled={
                !available ||
                receiptTotal -
                  exchangeInfo?.getExchangeReceiptInfo?.storeCredit! <
                  0 ||
                items.length === 0
              }
            >
              Create Receipt
            </Button>
          </div>
        </div>
      </>
    </form>
  )
}
export { ReturnReceipt }
